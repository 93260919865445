import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE71WYW/bIBD9nl+BKlVqpBE5aZJlRNv/mKZpInC2WTFYgJt0U//7BNgNdpy02ap9s8/c3bt3jzvPfsyrZdnoIkO/JwhxYWtJnwjKJRy2E4SYltoQ9EjNHcY0z4UC7ODgcG1ERc0TDgem/mgJoigdQatlHVx9CGxLI9QDQZm37AV3JUHzLLv1r1SKQmHhoLIEWWfAsdLbnaHKCie0ImhH2UNhdKN4zISy2cJ+QLqmTLin8OZd9COYXOo9QaXgHFSA3hjrsXPIaSPddvI8mb1US0rvEmoephhUGw6+lJnG+Mapo5hJwR7oTsJnZxr4HkJ2qWstlAPTc5uHEx1Zy1Uka6cNB4MN5aKxBG2i9aQdPcoYxOBpCefB41xICXwas/nOUO4J652vQDXtl3Cwogfcdu1+nUVQlVCdbRFtSXWLUF2tu/4ZkNSJR4jBTCEUlpA7gnBbYkdEJwrdOCkUEKS0Cl415VyoonXbxHyRem5oUSTUH1GQHeTaQGyGVg6UI+jmZtvDRndWy8aFLE7XBK0ihKC/XJuKxEdJHXy9w6vsNnASgaSCXvZLaV9TGcdPZ8T7igBrSRmUWvJEhieCWcScbegA7w0sBXmkXL10YxhvfjZeYhSqOMkRwh5hzVZ/G2gM5n0Ls5PjGOjjfUVfEMmFsQ6zUkgeldpT13w9kHPwkXTUxUQMm4HLfXqIoAytrrvLBa39bRhEXY6P5zMxTkdvrpXDVvyCgbiCvcT3/dG2Gs8WwnJhgEVVMy2bSp3giNkdNW7bZyLS25sqjEp25+8+wmi9rA/TLk/oHkL7UjjAtqYsjIS9obU3NxYMtiCBuW5SJPDXUXQjO+EiDbaavpzZtwpbZ9kFGAnQsBSPKUFKUVthj+RYkHmy5hK4H8/CPbt9LTCteH//XqztYN/I7D/UsblOo68rcpwAwbRKyx5oPQH06f0Atfxd1ZBE+YNl163S3g9RApz+P+Cn/3Ejw8NbvEBSoZxWd/lS7q6q6Wdjncg9rHZ5Bwyg+CstZ++XJU43HObxevyX9vkPz000Vj8LAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1m4huogb';
export var dateCell = '_1m4huoga';
export var dndCell = '_1m4huog2';
export var dragPageItemOverlay = '_1m4huog1';
export var iconCell = '_1m4huog8';
export var operationsCell = '_1m4huogc';
export var root = '_1m4huog0';
export var selectionCell = '_1m4huog4';
export var tagsCell = '_1m4huog9';
export var titleCell = '_1m4huog5';
export var titleCellMain = '_1m4huog6';
export var titleCellPreview = '_1m4huog7';
export var titleIconsWrapper = '_1m4huog3';