import "../../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61VwW7bMAy99yt0KdAcVCRp02XqbT+w666KRdtcZcmQ6Njp0H8fLMWO7ThpNvQmUCQfH/lEPeq3jVvVS/bnjrFUQ8MzZ2vBVq93jCn0pZYHES5aw+/KE6YHnlhDYChecDCqvSzQ8BoV5YKtNssyBBSy6WzraPu4e4yQqy+GXH4OuQ6QO5m8Zc5WRglW50jQRo0jYpYcMMtJsKflPm9tdg8u1bbmB8FkRXaQ+SlkJmiIk5PGp9YVgiWyRJIa3wNEag3x+pjzZbk8h+3TPYd0pfVIaI1gDrQk3MNsh0JDFDpIonNidVWYSMJlaPjOEtlCsPXzCGQTQCY+20g/1OrxHQTbS/fAuUxTNMCjvVic8dlEPonV1k1iQlc8JNYo6Q6LQQUvE5py562u4kDIloKFnBpSCsc+7luIOw3+vnXrprXaHke4s06BE2xVNsxbjWpclpYHcByNRwU/giuPEYtTMHdSYeUFe44pP1dnAobAtVdSY2Y4EhT+ZO4pbK9Q72axWkfU2IBNpDmQVzhqSfDrgW+W94tPB9f4xc1DGr4TPhcR+zdwytv38XMPTsuYoJRKockEW5dNJ6y+g2h0qOvYyNluXe3v/ITeORoFzXGbdJqYKP973AO24T6Xqt09xhr4H84EjnCiahmyn0nlAsO+SUcCE1XPrz9P0lF7nclSsJcRud3sXu0eQ0fUVtT2/2S4+cWnskAdBpiDQxoIamA572MQaykdGBoWK0SpZQK51QpcqPyiOgeOw3YnF6MwsYaXDotO0QOO6/HCVfMzO+u+L2UCfAdUA5grU+2+CsFyVCq6jibbI8M88uWlPkIcq2EWtf3heKi7HXbtZPnafVUnf9AaS49+UFg6Luy293p5eIMxTCT+rxXO87xFv4FBtxOMdYXUoy2xHmkifez34XVhjtfmLRu4RxyrMLuIs6uIJmI+ftzxc9iO8uRfXe94SX38BYwxI/k1CgAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var InputContainer = 'lk5r1wa';
export var fontItem = 'lk5r1we';
export var fontItemContainer = 'lk5r1wd';
export var fontLabel = 'lk5r1wf';
export var menu = 'lk5r1w2';
export var menuTrigger = 'lk5r1w3';
export var notFound = 'lk5r1wh';
export var preViewLabelWrapper = 'lk5r1w1';
export var searchIcon = 'lk5r1wc';
export var searchInput = 'lk5r1wb';
export var selectedIcon = 'lk5r1wg';
export var settingWrapper = 'lk5r1w0';
export var shapeIndicator = 'lk5r1w9';
export var snapshot = 'lk5r1w7';
export var snapshotContainer = 'lk5r1w4';
export var snapshotLabel = 'lk5r1w8';
export var snapshotSkeleton = 'lk5r1w6';
export var snapshotTitle = 'lk5r1w5';