import "../../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE71Vf2/bIBD9P5/iVGlSso3UTpq1ox9mwgYnqA5nYdIlnfrdJ4M9A/6hJqr2nw139949Htz6V/pw2h5SuYU/CwBCuv+EwlN1fl4AVFhLI1FRYFmN5cmIZtVgRSFpvrTcH0z7XYrCUHhletlXSlfNTobG4LENq1AqIzQRr0KZmoJCJZ4X74v1PzaUZqJALSyrHJURylC4u2uyuayrkl0oZCXmL9MU34hUXJwpbByBM6nlm1R7ChlqLjTJ0Db4W3JziFknlvVBuObG9lyvtp+M5S97jSfFKRjNVF0xLZRxsBYqxxJ1XGez8iJaFhsneruoGZenmsIu+eKt1uZSCgo1lpLHsrHCCP0pqqVxZ2PknQg5K/NlrBHcwyYQsW2t94vH/CE48N5cVs0C9bEVtmRGLC0cSdY7+Do4mdV3mN1exbC9YBaVpA1LL2QXMAtsfDO5D3Hb+dw63AG9H3b7v93RzoP2NJ0D50zd+XcbsX60rCNjzHjp3lUdtc5jcEBeEZKOiRzkPtmc8OpZ1eZYeDL5pYYO/igL1qeEgT+juGsg4Buk1TkskNlEPfamRVBZANVmXAeWB8adRctH79h1cNw7/lkwfpVfxqDE5Ky87ZGdvGqDx5cVhVSCVFoemb64qRJxm5ucN0666dE53l33RAy09N+LiVbGR9/7XzVF9z2oCAAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottom = '_14u3h1ic';
export var edgeLine = '_14u3h1ie';
export var horizontal = '_14u3h1i7';
export var horizontalIndent = 'var(--_14u3h1i1)';
export var indicatorColor = 'var(--_14u3h1i2)';
export var left = '_14u3h1id';
export var lineAboveStyles = '_14u3h1i4';
export var lineBelowStyles = '_14u3h1i5';
export var localLineOffset = 'var(--_14u3h1i9)';
export var outlineStyles = '_14u3h1i6';
export var right = '_14u3h1ib';
export var terminalSize = 'var(--_14u3h1i0)';
export var top = '_14u3h1ia';
export var treeLine = '_14u3h1i3';
export var vertical = '_14u3h1i8';