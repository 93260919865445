import "../../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61WwW7bMAy99yuI7rIeFNipk3QqdtlvDMOgWLStRpEMSW7TDfv3QXKcyY6d2sBOBiSRfHwkH736meYvu+yQJfD7DuDITCkUcbqm8JjUp+e7P3er7kkaPaHAGqef/5nstXP6SCHzRgAOT44wKUpFIUfl0PRcrSeiAeRaakPhlZnPhLCiEApJcGYx14oz807Ckwf/uNDKESt+4cAgnJ9seCP9QYWirByFdLXp4XhcjqMSZSW9N1Jog6XRjeLEIP8/gNbAAqSx8FKowyX5ns3KX7V2jbHesNaiJX2Zqyw4qbUVTmhf5L3VsnHo/ZgWcfI8YIyst4NG2cSsdo1x1U7beYkC7Fl+aHmm4AxTtmYGlQtX2nA05OxkcLm4GOt1W/vGoiEWJeaOgtIKe7h3AfdN5/b4sLiXPya9YzLpwXkKcLoUdrs2BS5sLdk7hUJiOPBfwoXBvA2Sa9kclb95aawThYeiHCr3b14BwgAT4fBo4+ObKvEl4LmKfyNKyWoKTy3u0Xp8zHUUf3p+AvW1EccREXk7h9wmfXb3wdub4K6ikG1miVves0mmbeZ10ZltiYXXi+20PO1lg9cZZYOMeEA3DmdJ1SZ6Y87Q9fBtkitF2Z55Hq6WYavheKvNgXBpsSRqsQGkmVpaNwbJWyUcDtSKjBbJsO6mHXvGuVAlhaRbnWdNM4yLxl4W6tJKxDR958wxIizZoxT4iubrvTMN3v8I/F0D/vS4y9JN2nNSjHMdZjc9z+lESwy5jkvdmQ5K3R5H4ctWdCWeKKSx0ySi7GJdn8BqKfhgrbB3NEQoKzh+6yz8py8g1W0BuWj3zIUfeRazpGn2j8R1z0axXm7H8it2eQKHWU4jAzkxoZPLaLyX/wKf1G3ppgoAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_1cj74k4d';
export var authMessage = '_1cj74k42';
export var authModalContent = '_1cj74k40';
export var captchaWrapper = '_1cj74k41';
export var errorMessage = '_1cj74k43';
export var forgetPasswordButton = '_1cj74k47';
export var forgetPasswordButtonRow = '_1cj74k44';
export var linkButton = '_1cj74k46';
export var resendCountdown = '_1cj74k4b';
export var resendCountdownInButton = '_1cj74k4c';
export var resendWrapper = '_1cj74k48';
export var sendMagicLinkButtonRow = '_1cj74k45';
export var sentMessage = '_1cj74k4a';
export var sentRow = '_1cj74k49';
export var skipDivider = '_1cj74k4f';
export var skipDividerLine = '_1cj74k4g';
export var skipDividerText = '_1cj74k4h';
export var skipLink = '_1cj74k4j';
export var skipLinkIcon = '_1cj74k4k';
export var skipSection = '_1cj74k4l';
export var skipText = '_1cj74k4i';
export var userPlanButton = '_1cj74k4e';