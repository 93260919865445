import "../../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE8VW0ZabIBB9z1fw0nPaB/aotWnKfg2RUdlFYIFsTHv67z0iEtmYbMxLX2JkGGa4c+eOTy+0e9v2GfqzQUi9g6mFOhLUcsZAPm8QctA7fDaAEFxbbgfTseUOsNW0AoKkOhqqnzd/N0/jkbk/knGrBT0RVAvoB6eXg3W8PuFKSQfSEeT98R7cEcaIDdUE7bTfTQVvJOYOOktQBdKBGZY1ZYzLhqB8q/vxZxf+zDIoljMYnphxA5XjShJUKXHofOQjZ64lKM+yL8NrC7xp3fn9Ep4Y6vv1UATlnziXy86f4hAP+PEo1BHHItM9Ksox2F4ZBgY7pQnKdY+sEpyhd2q+YkzrmkvAYUulhDLfIqgDAwgaeRDyz4ukJFufaa2kw5b/BoLyENMvHQPgZZYNa2KINBWhKMaNPiRJs/EctVApyag5zbKK9ysjOx65Xkz/55i+r2pxm0vhCAG1ux/FiSS4n7dgXD0RRA9OzRLaXe3bBNDtAGh0+uWdIjbZRLIV0Maz6DL1rvStp8S84oEEobZpwbNx8Ubj7FcFD93d0X7qCF8nWr02Rh0kw0v3n5m14V1KryXcR9YHqi0q5MSN+2kRFgxl/GCjJiTkHuSvTFqtWl+ZPLssTblUmrSp2VUSrtDeudr5XbY1XL4SNGcupMwdeHMeASsD3qfN9X+fYs1Mc/KVd7xxsXb5YotSMsWOznztZE1HaTznxZ/TUdNwGaRybPu449XvSAZztIkbts7bljr6YxtHF3kxm27J0iNiqe5OasrDnQQQxB0VvLpQ9V2i6jrtDT/ximTiJfoR/d7GGnCJA5K7tALG2z+RyXbgzWjBNRcC2PzeNk0t+dg4WDDYgoDKDRIp4YMATXinRSgf+BgIKOyVc6q7e/T/A8u57lohCwAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = 'jamq6xq';
export var bottomButton = 'jamq6xp';
export var bottomLeft = 'jamq6x4';
export var collectionEditContainer = 'jamq6xi';
export var confirmButton = 'jamq6xj';
export var ellipsis = 'jamq6x0';
export var includeItem = 'jamq6xb';
export var includeItemContent = 'jamq6xa';
export var includeItemContentIs = 'jamq6x9';
export var includeItemTitle = 'jamq6x8';
export var includeListTitle = 'jamq6x6';
export var includeTitle = 'jamq6xc';
export var pageList = 'jamq6xl';
export var pagesList = 'jamq6x3';
export var pagesTab = 'jamq6x2';
export var pagesTabContent = 'jamq6x1';
export var previewActive = 'jamq6xr';
export var previewCountTips = 'jamq6xn';
export var previewCountTipsHighlight = 'jamq6xm';
export var resultPages = 'jamq6xk';
export var rulesBottom = 'jamq6x5';
export var rulesContainer = 'jamq6xh';
export var rulesContainerLeft = 'jamq6xg';
export var rulesContainerLeftContent = 'jamq6xe';
export var rulesContainerLeftContentInclude = 'jamq6xd';
export var rulesContainerLeftTab = 'jamq6xf';
export var rulesContainerRight = 'jamq6x7';
export var rulesTitle = 'jamq6xs';
export var rulesTitleHighlight = 'jamq6xo';