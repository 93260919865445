import "../../component/src/ui/notification/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE31Ty27bMBC85yu2BgrUBwaS7aQpg3xJkQMtrqRNaZIgV5bdIv9ekPRDdpwcubOcmR0u7zfjsKzXj/DvDmDtgsYggtI0RAlPfvd8ro6kuZdQXxQj7w1KiM6QLuWdiL3SbpSwVeGHEKptyeKhKOp57lLNny64wWrROOPCsbd4qeYTgRv4IuM3gET+fndfDj/zRF5pTbaTUD8m3yf0KaOaojdqL6E1mKdShjoriHETS1FEVoET9DZEpnYvGmcZLUuIXjUo1sgjop1Q/8rUPVLXs4TFquT1tVSDljFMSFQmOUT+KYc3ycIVB8BGhY6sCMVBXV1MriBuuyl7XVXfnyeOj+cbAa+mAa+nJFW6kQPrQnr7Op3dFkNr0rEnrVNIAIw7FmcAjSEfKSZo7IlR5FglWDcG5TOrsyzGg7lVlZVMWqnriHNjpL8ooX4opS+TaPIAVy2Xa6Jzy40klnkHvYvE5KyEgEYxbfFyuyVwUDZ6FdDyeanTdBYnMvhbK1aiNU7xy4zDgLPXsr4nAbWOzgycBdj5o1OA28bbz4w/zOEbbbwLrJKl04VuGkYWePiQ6sWHLvXN/ONzLIqTMtNI3AtqnH2Fo1IB8v6/zJjYnKYtn1UYbFnCMj/r+39BstyLoQQAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = 'mwu31bc';
export var actionButton = 'mwu31bd';
export var actionTextColor = 'var(--mwu31b3)';
export var card = 'mwu31b6';
export var cardBorderColor = 'var(--mwu31b2)';
export var cardColor = 'var(--mwu31b0)';
export var cardForeground = 'var(--mwu31b1)';
export var cardInner = 'mwu31b7';
export var closeButton = 'mwu31be';
export var closeIcon = 'mwu31bf';
export var closeIconColor = 'var(--mwu31b5)';
export var headAlignWrapper = 'mwu31b9';
export var header = 'mwu31b8';
export var icon = 'mwu31ba';
export var iconColor = 'var(--mwu31b4)';
export var main = 'mwu31bg';
export var title = 'mwu31bb';