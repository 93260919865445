import "../../core/src/components/page-list/page-group.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/page-group.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE6VU7W7bMAz8n6cQAgxogClwnC5LPRToewzDIEu0w1WWDEnOkg1598GSvcqOnaLtL3+QOvGORz49w7kwrAJLfm4wNTzfbsjfBSHJJ/8gRNeMoztnJPnmvw+A5cFlJKlP7Y/LgpBNcp29GWYfmbmj1DCBJ8q1lKy2mEugXCsHytGQtwqIl8XTdVnpTFkfuWi69Dmil8W6LybxZwTaWrJzRgoJPqd9UoEGuEOtMsK1bCrVRkpWZ2TXIkUoW4+ij2AKqX9n5IBCgBqmfBfMMWodc/C41DWo5Q9/iimsWLjlpXHJemsJMAsUFdWNuwHFpbYg5sHS22D37xLgfiTAl2kUJrFUFB1UNiMclAPT/q6ZEKhK3xGy2dUn/7IL7em7le7Dd874c2l0o0TvCVYUqIC+BGhtsGLm3PpEG2+JxoKhFiRwlxGlFQzLzQ5ts3zRs/g+JUDSAqUEsRqA7Pzxlqr3bhT56iO8MVabjNQaA/EoYx/OauWoxT8wujn8rzwRf/8o7uDkqAWulYhZR/gPH8cXaFmYtyt0Nt3t4dzN9P5XYx0W536M49CVYK8wONnVwDDdfOfaCDB+czS2s2rsObIfmZe90Q2rSS+N1InXWpSdD4PJiGTPYaoryLWKHO4MUxbDdPr3QpuKJOt0ftbzsDi6bQricVkwaaHbHf9BMmJ0u1vuHhIB5Sts88/kBr4zTQ8/Iwh/0+a44Z5R29Mg5LxKkcyoDmBwqBW/xeVaKjqhVQfRDdKMGsl66+0ZPE/hCMrZiZXF32HSf/L4stoUCAAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = '_1i2rcb3b';
export var collapsedIconContainer = '_1i2rcb3c';
export var collapsibleContent = '_1i2rcb33';
export var collapsibleContentInner = '_1i2rcb34';
export var header = '_1i2rcb35';
export var headerCollapseIcon = '_1i2rcb37';
export var headerCount = '_1i2rcb39';
export var headerLabel = '_1i2rcb38';
export var root = '_1i2rcb30';
export var selectAllButton = '_1i2rcb3a';
export var spacer = '_1i2rcb36';