import "../../core/src/components/page-list/selector/selector-layout.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/selector/selector-layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61SwU7DMAy97yt8QYJDpmwMGOHGn2SNuxmlSZR4rAPx7yjZWrW0HJC4NJL7/Pz8npdUfzRtlPC5ADCUgtVnBbXF9mUB5RWGIlZM3imovD02Lv85IO0PrGAl5c3L4muxvPCsCs/OR4NR7DyzbxSsQgvJWzLwruOtELquyaGw+oxRkEtk8LXryM9dHtCQE92Qx01oB0PWZciJDB86AT8FAfgjW3KowHmHuRC0MeT2CtYytMNPIQeovWOR6AMviFzKBL2IEe50LW6kLEBkxihS0FUZIeRyPZJ8XyR3VLLzVsFqANoMzWMf/s25yfLX6ignYbFmEbWhY1JA7oCReIqKmXgONjmet2Niqs+i8o7RsYJsD4od8gmxHJG2tHeCGJukoELHGAd2PMzf5GwTwF5nv7aTLEfGlXqb7qbhyplwH3K4vZzHv8t5Gt3AUyGovPXxh6wQqdExO2V9zrBv2ZaWPrxNaK8r9ojneVVl/GWpHqp/JfsGODuaLgUEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = 'ifzmxra';
export var clearButton = 'ifzmxr8';
export var content = 'ifzmxr3';
export var footer = 'ifzmxr4';
export var footerAction = 'ifzmxr9';
export var footerInfo = 'ifzmxr5';
export var header = 'ifzmxr1';
export var root = 'ifzmxr0';
export var search = 'ifzmxr2';
export var selectedCount = 'ifzmxr6';
export var selectedNum = 'ifzmxr7';