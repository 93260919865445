import "../../core/src/components/affine/setting-modal/general-setting/billing/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/billing/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61U227bMAx9z1fwZUD7oMDOkjRTgf3KIFu0zcGWBElunA3798FKslq+1Gu3J0OiyHNInuPtt7Q5uLNyKfzcAEhyphYXDkWN3fMGwpdJsph70opDruu2UX1E1FQqRh4bxyFH5dH216UwHHZ70z1vfm229+q7UP1M0lcc0iT5FEU/z2N/b52n4sJyrTwqz8EZkSPL0J8RAwcjpCRVckh3JqRk2kq0HFLTgdM1SXgR9oExURSkkF3DLNe1to+v75kVklrH4TSivQ/Eej4cUkggiaKHEG2ELUkxr800/3jN18ozRz+Qx2zCfcWOgUk4nJHKynM4JjHS03qdTDh8jJJOE3p/1iKFKtHq1jGH3pMqYatEgyEhDGeEgNbq17ENML4spnjsPHOYayWFvQxG/mYXnYvri/XGQ8pofod+fm/wwsZUwpEb0Mpb6/rHRtNVygMW2Xu9MW+C/L1lGlKsurX0dFP4gu0mXrmHBvjyoza7aSjT3utmqnJcX5JrFkQOUPeP7l3ubl0ubs5YaoZ6GtAo/lorMWRyhYy47VcENBV2ROQriEVjZFYoOfZDJNxBqfKf/sqoZFStGk8oPc30PruX48f3Qv9xL2vGntnLbyoz2pfiBgAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var believerHeader = '_1m5swnsd';
export var believerPrice = '_1m5swnsh';
export var believerPriceCaption = '_1m5swnsi';
export var believerPriceWrapper = '_1m5swnsg';
export var believerSubtitle = '_1m5swnsf';
export var believerTitle = '_1m5swnse';
export var billingFrequency = '_1m5swns7';
export var billingHistorySkeleton = '_1m5swnsc';
export var currentPlan = '_1m5swns4';
export var currentPlanName = '_1m5swnsa';
export var history = '_1m5swns1';
export var historyContent = '_1m5swns2';
export var noInvoice = '_1m5swns9';
export var paymentMethod = '_1m5swns8';
export var planAction = '_1m5swns5';
export var planCard = '_1m5swns3';
export var planPrice = '_1m5swns6';
export var subscription = '_1m5swns0';
export var subscriptionSettingSkeleton = '_1m5swnsb';