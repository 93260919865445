import "../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61W227jNhB9z1cQBgokKGjIlzgJgwD9jyIoxuJI4poiBZKy11vsvxeiLiZlWes2fbIpDofDM2fOzB8HPGcGSrTkr9U5rXbH0578/UBI8pv/IURXkAp3ZmT1/kDIzwdCdjN7L9d7Sb/3OnPubebcKrl58OfDsg975U1SLbVhRKgCjXDvocHaG3BhKwnnxkQKhTST+L3xBVLkigqHpWUkReXQNJ/32nA01AAXtWVkW3ljh98d9ScYkZi594mbCTkJ7grmw2+WpVC0QJEXjpFN0jqqLRpqUWLqGFFaofdUG9u4qrTow6iAc6FyRpI+hEwrR634gYwcwTxSClnm3+O/l0/+lLbCCa0YMSjBiaN3X4LJhaJOV4z4MEKMWKGPaDxSe0gPudG14qMrvAn1732KT//JwQGFtLnqY+FMjYvPr/niwsJeIo+89QBxzKCWIfqRb58li6lWHMx5uIT0wFI8onK2B37icgN5LlQeXX7h5/I5OrSJ+fULYnXk8CRvTDtKT6csh4qR1XqUrK2/cPoxoSfYWy1r5782bO1u9QSgq46JA1nbZU/U1Zghzy0IRzSZ1CdGCsE5qqEoLhsopaissN55IRxSW0GKTYAnA9UMNAEcN9NaGVGCOft0+jruA45Mg52YXbt/lSvTuk5uwxqL1q/51ZXZKJ5Y3cZehBJOgIyDGD4G3l+mH/ettk5kTR0oh8qFD5zn6HrEiX49lRyRahUlJ9Cp9ZhLrz7Qnnm7EfO69b1JmnneSMTXnYgbUD2M/n+mTUmS5dre+bjwJa1gpFpKqOxIrgbnjBjtwOEjfUs45pMuJgUv0JzNXQR7/ZKOv13CnsSHIFikQlFdxy327eswQEzeq5cB00qeaVoIyccN/boUUm8Sdrwu+V1Dpa0etrD0h5KbzTPwzOexvWz0hAn6z1BXXSGN+LkblQl2Oj8bD7bQO4NNZqwzddpYfyxKOGAL1+KztbEos7a3IfeC/bHIQFpcfDLIXMeaoYwWi6gK91Knh9tC+IMKxQf5vpN6fVuaSkM7pgSopSDTRz8S0qsDT3Hrauau/xuidl9XqKZQAyVKaFG5jNMrS4TKGnqi71VgorCyNr39hPc6yYlhTqBcGEw7IugTNXhEY2emhOx2Uq8Q/X0e0WT53AV3k/Zt1JfE7rVzupztnZfuGkU9LWBXepCxdtz8j1KX3TWyeumqwKCK9a4IG9hmHTewfh12wO0oOcI7CCxW4+L/dmXx0rqNpp51d5dE1/SFZtLyZKLJcrsJwjh19tskhvtwpZPN/j9xNbTLHQ4AAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1ycp6vwa';
export var collapsedIcon = '_1ycp6vw9';
export var collapsedIconContainer = '_1ycp6vw8';
export var contentContainer = '_1ycp6vwc';
export var draggedOverEffect = '_1ycp6vwe';
export var draggingContainer = '_1ycp6vwd';
export var iconContainer = '_1ycp6vw7';
export var itemContent = '_1ycp6vw5';
export var itemMain = '_1ycp6vw3';
export var itemRenameAnchor = '_1ycp6vw4';
export var itemRoot = '_1ycp6vw2';
export var levelIndent = 'var(--_1ycp6vw0)';
export var linkItemRoot = '_1ycp6vw1';
export var mobileCollapsedIconContainer = '_1ycp6vwi _1ycp6vw8';
export var mobileContentContainer = '_1ycp6vwk _1ycp6vwc';
export var mobileIconContainer = '_1ycp6vwh _1ycp6vw7';
export var mobileItemContent = '_1ycp6vwj _1ycp6vw5';
export var mobileItemMain = '_1ycp6vwg _1ycp6vw3';
export var mobileItemRoot = '_1ycp6vwf _1ycp6vw2';
export var postfix = '_1ycp6vw6';