import "../../core/src/components/affine/setting-modal/general-setting/plans/layout.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/plans/layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE6VV227bMAx9z1dwDwOaYQrsNM1a92W/MQzDwFh0olWWDInOpUP/fbBk147nNB32FIOkDslDHubrE50KhyV5+Pkgi7rmDfyeARTOluEDoES3VUZsLLMtMxBpklTHxxnAywyA7XRUEgNeZosWNglxUvlK4ymDQlPAaH6FVI5yVtZkkFtdl6bxbLHKYLlqUvUoaUBpc2kqOIMcdX4jUvgEe3Q3QnhiVmYrSitRiy1W4jifN4AVStk44rPLwU3sQUneTQcFVwiye3KFtgdxzABrtsMkHQ/LNHLV2dlWvbHtI9hENPatLqcJQ622Riim0meQk2Fyjbn25IQnTTlnYKyhxzCcOt8JbKntrDtS2x1n8BCLaFtNk+TjMP1tSC+EQ6mOwufOai3QEQre1eVGdCiriLLB/GnrbG1kxxoWhTIkVG6N8JRbI9GdIm8V5opPGSSL9ZDHDHZKSjLDIjt46yS5UEztW2tf6+qfqAqL1eLmVls3qlgr8ySCYz7ujB0aX6Ejw4OqWpCRs7CGhVfPNIIP9qOfDxu4e0cDntHxYGu6Dbs/52IdoNqhJp3EMkiHQV+ixptKDi3R6yS5WvUGPQVOdGPqRrRcnldw/zf46h3ggZIxdHJ5TExH7hfrdWB9HQ/TpP6qPauieWCYDGfgK8xJbIgPFHfvwt6MeB+fJgzp+t1ufN8lMopc21qKvfJqo+kHXHhwNqA8+CrrVdQubrzVNQf9PgtlZDvSZgdjOenyTM5Tl6vteHDBLqr2sFMcRz1S3v1Qjxmk1RG81UqePx8KI6AEbbS9hO/CuhKSxe3aA6EnSBYr/7kjo3H09rV/YyjXp9le3shQ2Kj4la67Vo7C71A29+esiWgU6Xxi+P2fIBpVYmzs9Q90WP6dh8K6Azrpr25EnPorPy1VGpm+3YSEo/PZAEq1P39+tvDwQZWVdYzNTerXS/YSvSRHX84fL9yICfW/IdHKqXJaoHS9ivYoTB2T/z4UfwBrp0Fu+wgAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineCloudHeader = '_9dfuut9';
export var aiDivider = '_9dfuuta';
export var aiScrollTip = '_9dfuutc';
export var allPlansLink = '_9dfuut4';
export var cloudScrollTipCaption = '_9dfuute';
export var cloudScrollTipTitle = '_9dfuutd';
export var collapsibleHeader = '_9dfuut5';
export var collapsibleHeaderCaption = '_9dfuut8';
export var collapsibleHeaderContent = '_9dfuut6';
export var collapsibleHeaderTitle = '_9dfuut7';
export var plansLayoutRoot = '_9dfuut0';
export var scrollArea = '_9dfuut1';
export var scrollBar = '_9dfuut2';
export var scrollThumb = '_9dfuut3';