import "../../core/src/components/affine/setting-modal/general-setting/plans/ai/ai-plan.css.ts.vanilla.css!=!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/plans/ai/ai-plan.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61UwW7bMAy99yuIAgO2g4rYcZNWBXrphxSyRDvCbMmT5CTd0H8fLCuZZTttmuVkiJb4Hvn4ePearH+tyt1yAX9uAHJtBBoKSbMHqyspYMvMd0JYUUiFpP9NuK60+fF0vE8ME7K1FJJVs+/CDRNCqpLC0gfeb+4OMImHEdI2FXujUFToH3RfIqRB7qRWFLiu2lp1f0rWUHjo09bMlFKRXDunawpJOkqe+uSFVo7sUJYbR+F+sXg6xKz8jTQuyMf31tdSdYFNeJcuekhf6uhRbpgSxyYM8JeX4dt6Bj89je9w70hjZM3M2xyNzNM4++WI8WrCOA3tjwhOlL2/SNkk1MkqWSoiHdaWgnXMuBnB02yEuZrHHGQeXF77y7VUZCeF23TTGkQ+1rTuz+OpDvVfRdgkC81E59AQ2zDunUKSbxHbh49FxLrZMCvtQMVgOlJh4SZWJCbAj2V7/Dezn/gjKj/ryz/bM560Ra6VODG1j/AM7KuT68MCuTasH69WCTQdqyg3+4/ZHKTJr5OGX3FNfNbyacsm9CPrcVQOzZH72HAcnsFuy5MyxasRIDgtHRmtPw/yios6O2aH08Zm5+//L4ztWIUs9lrvwMMKb7SVPX2DFXNyG88mUppjoQ2GpiqHylG4vR30L4vbl82uqQCXM/6zNLpVggJvjUHlXjraMRWWW121DvtV5BdG0Mjp5nB4/wtFwLq+HggAAA==\"}!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionBlock = '_17q6gw35';
export var actionButtons = '_17q6gw36';
export var agreement = '_17q6gw39';
export var benefitGroup = '_17q6gw3b';
export var benefitItem = '_17q6gw3e';
export var benefitList = '_17q6gw3d';
export var benefitTitle = '_17q6gw3c';
export var benefits = '_17q6gw3a';
export var card = '_17q6gw30';
export var learnAIButton = '_17q6gw38 _17q6gw37';
export var purchaseButton = '_17q6gw37';
export var title = '_17q6gw34';
export var titleBlock = '_17q6gw31';
export var titleCaption1 = '_17q6gw32';
export var titleCaption2 = '_17q6gw33';